import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import { WidgetCategory } from '@aurora/shared-client/types/enums';
import type { EndUserPages } from '@aurora/shared-types/pages/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import endUserComponentRegistry from '../features/endUserComponentRegistry';
import { Feature } from '../features/enums';
import { WidgetRegistry } from './common/Widget/WidgetRegistry';
import type { WidgetInfo } from './common/Widget/WidgetRegistry';
import useKnowledgeBaseProperties from './guides/useKnowledgeBaseProperties';

const log = getLog(module);

/**
 * User defined type guard for WidgetCategory
 *
 * @param category the category
 */
function isWidgetCategory(category: string): category is WidgetCategory {
  return Object.values(WidgetCategory).includes(category as WidgetCategory);
}

/**
 * Return helper function to get allowed widgets for page and category filtering by enabled features
 */
export default function useAllowedWidgets(): {
  getAllowedWidgetsForPageAndCategory(
    identifier: WidgetCategory | string,
    pageName: EndUserPages
  ): Record<string, WidgetInfo>;
  hasWidgetsInCategory(widgetCategory: WidgetCategory, pageId: EndUserPages): boolean;
} {
  const { isGuidesAndChaptersEnabled } = useKnowledgeBaseProperties(module);

  const {
    publicConfig: { occasionsEnabled: isOccasionsEnabled, auroraFeaturedWidgetsEnabled }
  } = useContext(TenantContext);

  const featureMap = {
    [Feature.EVENTS]: isOccasionsEnabled,
    [Feature.GUIDES]: isGuidesAndChaptersEnabled,
    [Feature.FEATURED_WIDGET]: auroraFeaturedWidgetsEnabled
  };

  const featureWidgetDescriptorMap = {
    [EndUserComponent.FEATURED_GUIDE_WIDGET]: isGuidesAndChaptersEnabled
  };

  /**
   * Returns the widgets that are allowed to be added to the current page based on the specified category
   *
   * @param widgetCategory the target WidgetCategory
   * @param pageName the page name
   */
  function getAllowedWidgetsForCategory(
    widgetCategory: WidgetCategory,
    pageName: EndUserPages
  ): Record<string, WidgetInfo> {
    return Object.fromEntries(
      Object.entries(WidgetRegistry).filter(([id, widgetInfo]) => {
        if (!id || widgetInfo.category !== widgetCategory) {
          return false;
        }
        const { allowedPages, feature } = endUserComponentRegistry.getWidgetDescriptor(id);

        // Check if the widget's feature or descriptor is disabled
        if (
          (featureMap?.hasOwnProperty(feature) && !featureMap[feature]) ||
          (featureWidgetDescriptorMap.hasOwnProperty(id) && !featureWidgetDescriptorMap[id])
        ) {
          return false;
        }
        return !allowedPages || allowedPages.includes(pageName);
      })
    );
  }

  /**
   * Returns all allowed widgets for a specific category. The category may be specified by either providing the category
   * directly or by providing a widget id.
   *
   * In the instance that a widget id is provided, all widgets in the same category as the widget are returned
   *
   * @param identifier the widget category or widget id
   * @param pageName the page name
   */
  function getAllowedWidgetsForPageAndCategory(
    identifier: WidgetCategory | string,
    pageName: EndUserPages
  ): Record<string, WidgetInfo> {
    if (isWidgetCategory(identifier)) {
      return getAllowedWidgetsForCategory(identifier, pageName);
    } else if (EndUserComponent.CUSTOM_COMPONENT_WIDGET === identifier) {
      // not an error -- custom components render via this widget based on the id of the custom component
      return {};
    } else if (!WidgetRegistry[identifier]) {
      log.error(
        '%s does not exist as either a widget in the WidgetRegistry or a widget category',
        identifier
      );
      return {};
    } else {
      const { category: targetCategory } = WidgetRegistry[identifier];
      return getAllowedWidgetsForCategory(targetCategory, pageName);
    }
  }

  /**
   * Checks if the widget category contains widgets or not
   *
   * @param widgetCategory - The widget category
   * @param pageId - The Enduser page id
   * @returns boolean - true if there are widgets in the category else false
   */
  function hasWidgetsInCategory(widgetCategory: WidgetCategory, pageId: EndUserPages): boolean {
    return Object.entries(getAllowedWidgetsForPageAndCategory(widgetCategory, pageId)).length > 0;
  }

  return {
    getAllowedWidgetsForPageAndCategory,
    hasWidgetsInCategory
  };
}
