import type { QueryHookOptions } from '@apollo/client/react/types/types';
import knowledgeBasePropertyQuery from './KnowledgeBaseProperties.query.graphql';
import type {
  KnowledgeBasePropertiesQuery,
  KnowledgeBasePropertiesQueryVariables
} from '../../types/graphql-types';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';

/**
 * Gets knowledge base properties (read-only settings) for the current community.
 *
 * @param module The module
 * @param options Optional options to pass to the query
 * @author Amit Agrawal
 */
export default function useKnowledgeBaseProperties(
  module: NodeModule | string,
  options?: QueryHookOptions<KnowledgeBasePropertiesQuery, KnowledgeBasePropertiesQueryVariables>
): {
  loading: boolean;
  isGuidesAndChaptersEnabled: boolean;
} {
  const { data, loading } = useQueryWithTracing<
    KnowledgeBasePropertiesQuery,
    KnowledgeBasePropertiesQueryVariables
  >(module, knowledgeBasePropertyQuery, options);

  const isGuidesAndChaptersEnabled =
    data?.community?.knowledgeBaseProperties?.isGuidesAndChaptersEnabled;

  return {
    loading,
    isGuidesAndChaptersEnabled: isGuidesAndChaptersEnabled ?? false
  };
}
