import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import MediaButtonHorizontal, {
  MediaButtonHorizontalContent,
  MediaButtonHorizontalLeft
} from '@aurora/shared-client/components/common/MediaButtonHorizontal/MediaButtonHorizontal';
import type IconTypes from '@aurora/shared-client/public/static/graphics/processed/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import localStyles from './PageEditorWidgetListItem.module.pcss';

interface Props {
  /**
   * The id of the widget
   */
  widgetId: string;
  /**
   * The title for the widget
   */
  title: string;
  /**
   * The description for the widget
   */
  description: string;
  /**
   * The widget icon
   */
  icon: IconTypes;
  /**
   * click handler for the list item
   */
  onClick: () => void;
}

/**
 * Renders a single interactive list item element in the list widgets a user may add in PageEditor
 *
 * @author Jonathan Bridges
 */
const PageEditorWidgetListItem: React.FC<React.PropsWithChildren<Props>> = ({
  widgetId,
  title,
  description,
  icon,
  onClick
}) => {
  const cx = useClassNameMapper(localStyles);

  return (
    <MediaButtonHorizontal
      key={widgetId}
      className={cx('lia-widget-btn')}
      ariaLabel={title}
      onClick={onClick}
      testId="PageEditorWidgetListItem"
    >
      <MediaButtonHorizontalLeft className={cx('lia-widget-btn-icon')}>
        <Icon size={IconSize.FROM_CSS} icon={icon} />
      </MediaButtonHorizontalLeft>
      <MediaButtonHorizontalContent heading={title} subHeading={description} />
    </MediaButtonHorizontal>
  );
};
export default PageEditorWidgetListItem;
