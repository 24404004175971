import type { QueryResult } from '@apollo/client';
import ConditionalWrapper from '@aurora/shared-client/components/common/ConditionalWrapper/ConditionalWrapper';
import QueryHandler from '@aurora/shared-client/components/common/QueryHandler/QueryHandler';
import PageContext from '@aurora/shared-client/components/context/PageContext/PageContext';
import { getComponentPageScopeForPageId } from '@aurora/shared-client/helpers/components/CustomComponentsHelper';
import type { QuiltWrapperWidgetCategory, WidgetCategory } from '@aurora/shared-client/types/enums';
import type { ComponentPageScope } from '@aurora/shared-generated/types/graphql-schema-types';
import type { ComponentResolveServerFragment } from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { ComponentsQuery, ComponentsQueryVariables } from '../../../types/graphql-types';
import { widgetIconByCategoryMap } from '../../common/Widget/WidgetRegistry';
import CustomComponentLibrary from '../../customComponent/CustomComponentLibrary/CustomComponentLibrary';
import useCustomComponentIds from '../../customComponent/CustomComponentLibrary/useCustomComponentIds';
import useTranslation from '../../useTranslation';
import localStyles from './PageEditorWidgetListContent.module.pcss';

interface Props {
  /**
   * The selected widget category
   */
  selectedWidgetCategory: WidgetCategory | QuiltWrapperWidgetCategory;
  /**
   * The component ids query variables to be used on the query to get custom components
   */
  queryVariables: ComponentsQueryVariables;
  /**
   * Whether is viewing html category or not
   */
  isViewingHtmlCategory: boolean;
  /**
   * Whether is viewing stock category or not
   */
  isViewingStockCategory: boolean;
  /**
   * Adds the created custom content widget to the selection.
   *
   * @param widgetId of the custom content widget.
   * @param name of the custom content to be rendered inside the widget.
   * @param customComponent any custom component props to add.
   */
  addCustomWidget(
    widgetId: string,
    name: string,
    customComponent: ComponentResolveServerFragment
  ): void;
}

/**
 *
 * Renders list of widgets and is used as wrapper for lists rendered in PageEditorWrapperWidgetList and PageEditorWidgetList.
 *
 * @author Luisina Santos
 */
const PageEditorWidgetListContent: React.FC<React.PropsWithChildren<Props>> = ({
  selectedWidgetCategory,
  isViewingHtmlCategory,
  isViewingStockCategory,
  queryVariables,
  addCustomWidget,
  children
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.PAGE_EDITOR_WIDGET_LIST_CONTENT
  );
  const { pageId } = useContext(PageContext);

  const customComponentPageScope: ComponentPageScope = getComponentPageScopeForPageId(pageId);

  /**
   * Queries for Custom Components applicable for provided template.
   */
  const queryResult: QueryResult<ComponentsQuery> = useCustomComponentIds(module, {
    pageScope: customComponentPageScope,
    ...queryVariables
  });

  if (textLoading) {
    return null;
  }

  return (
    <QueryHandler queryResult={queryResult}>
      {(): React.ReactNode => {
        const { components } = queryResult.data;
        const customComponents = components.filter(
          ({
            properties: {
              config: { applicablePages }
            }
          }) => applicablePages.length === 0 || applicablePages.includes(customComponentPageScope)
        );
        const hasCustomComponents: boolean =
          Object.entries(queryVariables).length > 0 && customComponents.length > 0;

        return (
          <>
            {isViewingStockCategory && (
              <ConditionalWrapper condition={hasCustomComponents} className={cx('lia-list')}>
                {hasCustomComponents && (
                  <p
                    className={cx('lia-heading')}
                    data-testid="PageEditorWidgetList.DefaultHeading"
                  >
                    {formatMessage('default')}
                  </p>
                )}
                {children}
              </ConditionalWrapper>
            )}
            {hasCustomComponents && (
              <ConditionalWrapper
                condition={isViewingStockCategory || isViewingHtmlCategory}
                className={cx('lia-list')}
              >
                {(isViewingStockCategory || isViewingHtmlCategory) && (
                  <p className={cx('lia-heading')} data-testid="PageEditorWidgetList.CustomHeading">
                    {formatMessage('custom', { hasAddButton: isViewingHtmlCategory })}
                  </p>
                )}
                <CustomComponentLibrary
                  componentIds={customComponents.map(({ id }) => id)}
                  icon={widgetIconByCategoryMap[selectedWidgetCategory]}
                  addWidget={addCustomWidget}
                />
              </ConditionalWrapper>
            )}
          </>
        );
      }}
    </QueryHandler>
  );
};

export default PageEditorWidgetListContent;
