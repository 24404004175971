import type {
  ForwardRefExoticComponent,
  MouseEventHandler,
  PropsWithoutRef,
  RefAttributes
} from 'react';
import React, { forwardRef } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import localStyles from './MediaButtonHorizontal.module.pcss';

interface Props {
  /**
   * Class name(s) to apply to the wrapping element.
   */
  className?: string;
  /**
   * Aria-label to apply to the wrapping element.
   */
  ariaLabel?: string;
  /**
   * Title to apply to the wrapping element.
   */
  title?: string;
}

interface MediaButtonHorizontalProps extends Props {
  /**
   * Button onClick handler.
   * @param event - the DOM event
   */
  onClick?: MouseEventHandler;
  /**
   * Children content of the button component.
   */
  children: React.ReactNode;
  /**
   * Test id.
   */
  testId?: string;
}

/**
 * Media Button Horizontal. This is designed to have an icon or component to the left of a block of
 * content and/or a title and/or sub-title. Optionally there is a content block that can be added
 * that is aligned to the right inside the component.
 *
 * @author Willi Hyde, Adam Ayres
 */
const MediaButtonHorizontal: ForwardRefExoticComponent<
  PropsWithoutRef<MediaButtonHorizontalProps> & RefAttributes<HTMLButtonElement>
> = forwardRef(function MediaButtonHorizontalForward(
  { className, ariaLabel, title, onClick, children, testId = 'MediaButtonHorizontal' },
  ref
) {
  const cx = useClassNameMapper(localStyles);
  return (
    <button
      type="button"
      className={cx('lia-hb lia-is-button', className)}
      aria-label={ariaLabel}
      title={title}
      onClick={onClick}
      ref={ref}
      data-testid={testId}
    >
      {children}
    </button>
  );
});

interface FauxButtonProps extends Props {
  /**
   * Type of element to use for Horizonal "Button" area. Default is div.
   */
  type?: 'div' | 'section' | 'article';
}

/**
 * Horizontal Faux Button. Just like the Horizontal button but wrapped in a
 * div, section, or article. There are no hover styles associated with the faux button.
 */
const MediaFauxButtonHorizontal: React.FC<React.PropsWithChildren<FauxButtonProps>> = ({
  type: Type = 'div',
  className,
  ariaLabel,
  title,
  children
}) => {
  const cx = useClassNameMapper(localStyles);
  return (
    <Type className={cx('lia-hb', className)} aria-label={ariaLabel} title={title}>
      {children}
    </Type>
  );
};

interface LeftProps {
  /**
   * Class name(s) to apply to the left side element.
   */
  className?: string;
  /**
   * For use with Faux button. Element type to use for the content area.
   */
  type?: 'div' | 'span';
}

/**
 * Left side of the Media Button Horizontal.
 */
const MediaButtonHorizontalLeft: React.FC<React.PropsWithChildren<LeftProps>> = ({
  className,
  children,
  type: Type = 'span'
}) => {
  const cx = useClassNameMapper(localStyles);

  return <Type className={cx('lia-left', className)}>{children}</Type>;
};

interface ContentProps {
  /**
   * For use with Faux button. Element type to use for the content area.
   */
  type?: 'div' | 'span';
  /**
   * Text string for the heading area.
   */
  heading?: string;
  /**
   * Heading className is applied to the heading element. This is only
   * applied when a heading value is passed.
   */
  headingClassName?: string;
  /**
   * Text string for the subheading area.
   */
  subHeading?: string;
  /**
   * Class name(s) to apply to the subheading text.
   */
  subHeadingClassName?: string;
  /**
   * Class name(s) to apply to the content element.
   */
  className?: string;
}

/**
 * Content area for Media Button Horizontal.
 */
const MediaButtonHorizontalContent: React.FC<React.PropsWithChildren<ContentProps>> = ({
  className,
  type: Type = 'span',
  heading,
  headingClassName,
  subHeading,
  subHeadingClassName,
  children
}) => {
  const cx = useClassNameMapper(localStyles);

  // Renders Heading element.
  function renderHeading() {
    return heading && <span className={cx('lia-heading', headingClassName)}>{heading}</span>;
  }

  // Renders subheading span.
  function renderSubHeading() {
    return (
      subHeading && <span className={cx('lia-subheading', subHeadingClassName)}>{subHeading}</span>
    );
  }

  return (
    <Type className={cx('lia-content', className)}>
      {renderHeading()}
      {renderSubHeading()}
      {children}
    </Type>
  );
};

interface RightProps {
  className?: string;
}

/**
 * Right side of Media Button Horizontal.
 */
const MediaButtonHorizontalRight: React.FC<React.PropsWithChildren<RightProps>> = ({
  className,
  children
}) => {
  const cx = useClassNameMapper(localStyles);
  return <span className={cx('lia-right', className)}>{children}</span>;
};

export {
  MediaButtonHorizontal as default,
  MediaFauxButtonHorizontal,
  MediaButtonHorizontalLeft,
  MediaButtonHorizontalContent,
  MediaButtonHorizontalRight
};
