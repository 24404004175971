import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import PageContext from '@aurora/shared-client/components/context/PageContext/PageContext';
import { WidgetCategory } from '@aurora/shared-client/types/enums';
import {
  ComponentMarkupLanguage,
  Groups
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { ComponentResolveServerFragment } from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { createObjectByPath } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { getLog } from '@aurora/shared-utils/log';
import React, { useContext } from 'react';
import type { SectionWidgetData } from '../../../helpers/quilt/PageEditorHelper';
import {
  isPageEditorSectionWidgetPlaceholder,
  PageEditorSelectionType
} from '../../../helpers/quilt/PageEditorHelper';
import {
  addWidget as sectionHelperAddWidget,
  addWidgetAndUpdateQuilt
} from '../../../helpers/quilt/SectionHelper';
import type { ComponentsQueryVariables } from '../../../types/graphql-types';
import type { WidgetInfo } from '../../common/Widget/WidgetRegistry';
import EditContext from '../../context/EditContext/EditContext';
import useAllowedWidgets from '../../useAllowedWidgets';
import PageEditorWidgetListItem from '../PageEditorWidgetListItem/PageEditorWidgetListItem';
import PageEditorWidgetListContent from '../PageEditorWidgetListContent/PageEditorWidgetListContent';
import { usePageEditorWidgetText } from '../UsePageEditorWidgetText/usePageEditorWidgetText';

const log = getLog(module);

interface Props {
  /**
   * Type of community widgets.
   */
  selectedWidgetCategory: WidgetCategory;
}

const defaultContentMarkupLanguages: Array<ComponentMarkupLanguage> = [
  ComponentMarkupLanguage.Handlebars,
  ComponentMarkupLanguage.Freemarker,
  ComponentMarkupLanguage.React
];

/**
 * Maps the widget category enum to the GraphQL query variables for the {@link ComponentsQuery}
 */
export const widgetCategoryVariablesMap: Record<
  WidgetCategory,
  Omit<ComponentsQueryVariables, 'pageId'>
> = {
  [WidgetCategory.CONTENT]: {
    markupLanguages: defaultContentMarkupLanguages,
    grouping: Groups.Content
  },
  [WidgetCategory.PEOPLE]: {
    markupLanguages: defaultContentMarkupLanguages,
    grouping: Groups.People
  },
  [WidgetCategory.PLACES]: {
    markupLanguages: defaultContentMarkupLanguages,
    grouping: Groups.Places
  },
  [WidgetCategory.CUSTOM_CONTENT]: {
    markupLanguages: defaultContentMarkupLanguages,
    grouping: Groups.Custom
  },
  [WidgetCategory.CUSTOM_HTML_CONTENT]: { markupLanguages: [ComponentMarkupLanguage.Html] }
};

/**
 *
 * Renders list of widgets.
 *
 * @author Shalin Amin
 */
const PageEditorWidgetList: React.FC<React.PropsWithChildren<Props>> = ({
  selectedWidgetCategory
}) => {
  const { contextNode } = useContext(AppContext);
  const editContext = useContext(EditContext);
  const { quilt, onChange, selection, setSelection } = editContext;
  const { pageId } = useContext(PageContext);

  const { getAllowedWidgetsForPageAndCategory } = useAllowedWidgets();

  const isViewingHtmlCategory: boolean =
    selectedWidgetCategory === WidgetCategory.CUSTOM_HTML_CONTENT;
  const isViewingStockCategory: boolean =
    !isViewingHtmlCategory && selectedWidgetCategory !== WidgetCategory.CUSTOM_CONTENT;

  /**
   * Adds a section widget to the quilt and sets it as the active selection in page editor
   *
   * @param widgetId the id of the widget being added
   */
  function addWidget(widgetId: string): void {
    if (isPageEditorSectionWidgetPlaceholder(selection)) {
      const {
        quiltClone: updatedQuilt,
        widgetIdx,
        instanceId
      } = sectionHelperAddWidget(quilt, selection, widgetId);
      if (
        widgetId === EndUserComponent.FEATURED_CONTENT_WIDGET ||
        widgetId === EndUserComponent.FEATURED_PLACES_WIDGET ||
        widgetId === EndUserComponent.FEATURED_IDEA_STATUSES_WIDGET ||
        widgetId === EndUserComponent.FEATURED_GUIDE_WIDGET
      ) {
        onChange(updatedQuilt, null, {
          componentId: widgetId,
          instanceId,
          featuredItemIds: [],
          coreNodeId: contextNode.id
        });
      } else {
        onChange(updatedQuilt);
      }
      const nextSelection: SectionWidgetData = {
        type: PageEditorSelectionType.SECTION_WIDGET,
        widgetId,
        location: {
          sectionId: selection.sectionId,
          columnId: selection.columnId,
          widgetIdx
        },
        props: {
          instanceId
        },
        sectionEditLevel: selection.sectionEditLevel
      };
      setSelection(nextSelection);
    } else {
      log.error('Add widget called with invalid selected location %O', JSON.stringify(selection));
    }
  }

  /**
   * Renders the widget button that when clicked adds the selected widget to the page
   *
   * @param widgetId
   * @param widgetInfo
   */
  const RenderWidgetItem: React.FC<
    React.PropsWithChildren<{ widgetId: string; widgetInfo: WidgetInfo }>
  > = ({ widgetId, widgetInfo }) => {
    const { icon } = widgetInfo;

    const {
      title: widgetTitle,
      description: widgetDescription,
      loading: widgetTextLoading
    } = usePageEditorWidgetText();

    const titleToUse: string = widgetInfo.title ?? widgetTitle(widgetId);
    const descriptionToUse: string = widgetInfo.description ?? widgetDescription(widgetId);

    if (widgetTextLoading) {
      return null;
    }

    return (
      <PageEditorWidgetListItem
        key={widgetId}
        widgetId={widgetId}
        title={titleToUse}
        description={descriptionToUse}
        icon={icon}
        onClick={() => addWidget(widgetId)}
      />
    );
  };

  /**
   * Renders a list of widgets that are allowed to be added to the page for a given category
   */
  function renderAllowedWidgetsForCategory(): React.ReactElement {
    return (
      <div data-testid="PageEditorWidgetList">
        {Object.entries(getAllowedWidgetsForPageAndCategory(selectedWidgetCategory, pageId)).map(
          ([widgetId, widgetInfo]: [string, WidgetInfo]) => {
            return <RenderWidgetItem key={widgetId} widgetId={widgetId} widgetInfo={widgetInfo} />;
          }
        )}
      </div>
    );
  }

  /**
   * Adds the created custom content widget to the selection.
   *
   * @param widgetId of the custom content widget.
   * @param name of the custom content to be rendered inside the widget.
   * @param customComponent widget custom component to add.
   */
  function addCustomWidget(
    widgetId: string,
    name: string,
    customComponent: ComponentResolveServerFragment
  ): void {
    const { widgetData, setWidgetData } = editContext;
    createObjectByPath(widgetData, name, customComponent);
    setWidgetData(widgetData);
    addWidgetAndUpdateQuilt(editContext, name, { customComponentId: customComponent.id });
  }

  return (
    <PageEditorWidgetListContent
      selectedWidgetCategory={selectedWidgetCategory}
      queryVariables={{ ...widgetCategoryVariablesMap[selectedWidgetCategory] }}
      isViewingHtmlCategory={isViewingHtmlCategory}
      isViewingStockCategory={isViewingStockCategory}
      addCustomWidget={addCustomWidget}
    >
      {renderAllowedWidgetsForCategory()}
    </PageEditorWidgetListContent>
  );
};

export default PageEditorWidgetList;
