import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import useTranslation from '../../useTranslation';

export interface PageEditorWidgetText {
  /**
   * Returns the title
   * @param id id of the component
   */
  title: (id: string) => string;

  /**
   * Returns the description
   * @param id id of the component
   */
  description: (id: string) => string;

  /**
   * true when text is loading
   */
  loading: boolean;
}
/**
 * wrapper hook to share text between two components in page editor for title and description
 * @author Manish Shrestha
 */
export function usePageEditorWidgetText(): PageEditorWidgetText {
  const { formatMessage, loading } = useTranslation(EndUserComponent.USE_PAGE_EDITOR_WIDGET_TEXT);

  return {
    title: (id: string) => formatMessage(`${id}.title`),
    description: (id: string) => formatMessage(`${id}.description`),
    loading
  };
}
