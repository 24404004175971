import type { QueryResult } from '@apollo/client';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import { getLog } from '@aurora/shared-utils/log';
import type { ComponentsQuery, ComponentsQueryVariables } from '../../../types/graphql-types';
import getComponents from '../../customComponent/Components.query.graphql';

const log = getLog(module);

/**
 * Returns the queryResult for the {@link Query#components} query
 *
 * @param module the node module
 * @param variables query variables
 */
export default function useCustomComponentIds(
  module: NodeModule | string,
  variables: ComponentsQueryVariables
): QueryResult<ComponentsQuery, ComponentsQueryVariables> {
  const result = useQueryWithTracing<ComponentsQuery, ComponentsQueryVariables>(
    module,
    getComponents,
    {
      variables
    }
  );
  if (result?.error) {
    log.error(result.error, 'Error fetching custom component ids');
  }
  return result;
}
